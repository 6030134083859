import firebase from 'firebase';

//import { FirebaseConfig } from "../data/firebase";
//firebase.initializeApp(FirebaseConfig);

let lobaRef = firebase.database().ref('loba-contracts');
export const lobaFb = {
  addContract: function(obj) {
    lobaRef.push().set(obj);
  },

  removeTask: function(id) {
    lobaRef.child(id).remove();
  },

  fetchAll: function(callback) {
    lobaRef.on("value", snapshot => {
      callback(snapshot.val());
    });
  }
}