const firebaseReducer = (state = {
  loading: false,
  tasks: []
}, action) => {
  switch (action.type) {
    case "FB_TASKS_ADD":
      state = {
        ...state,
        loading: true
      };
      break;
    case "FB_TASKS_ADD_SUCCESS":
      state = {
        ...state,
        loading: false,
        tasks: action.payload
      };
      break;
    case "FB_TASKS_REMOVE":
      state = {
        ...state,
        loading: true
      };
      break;
    case "FB_TASKS_REMOVE_SUCCESS":
      state = {
        ...state,
        loading: false,
        tasks: action.payload
      };
      break;
    case "FB_TASKS_FETCH":
      state = {
        ...state,
        loading: true
      };
      break;
    case "FB_TASKS_FETCH_SUCCESS":
      state = {
        ...state,
        loading: false,
        tasks: action.payload
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default firebaseReducer;