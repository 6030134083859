import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class Loader extends React.Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.active} className={this.props.className}>
          <ModalHeader>{this.props.header}</ModalHeader>
          <ModalBody>{this.props.body}</ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Loader;
