const web3Reducer = (state = {
  minning: false,
  saving: false,
  loading: false,
  contract: null,
  contractList: null,
  transaction: null,
  createContractError: null,
  loadContractError: null
}, action) => {
  switch (action.type) {
    case "LOBA_CREATE_CONTRACT":
      state = {
        ...state,
        minning: true,
        createContractError: null
      };
      break;
    case "LOBA_CREATE_CONTRACT_SUCCESS":
      state = {
        ...state,
        minning: false,
        saving: true,
        contract: action.payload,
        createContractError: null
      };
      break;
    case "LOBA_CREATE_CONTRACT_SAVED":
      state = {
        ...state,
        minning: false,
        saving: false,
        contractList: action.payload,
        createContractError: null
      };
      break;
    case "LOBA_CREATE_CONTRACT_ERROR":
      state = {
        ...state,
        minning: false,
        createContractError: action.payload
      };
      break;
    case "LOBA_CONTRACTS_FETCH":
      state = {
        ...state,
        loading: true
      };
      break;
    case "LOBA_CONTRACTS_FETCH_SUCCESS":
      state = {
        ...state,
        loading: false,
        contractList: action.payload
      };
      break;
    case "LOBA_GET_CONTRACT":
      state = {
        ...state,
        loading: true,
        contract: null
      };
      break;
    case "LOBA_GET_CONTRACT_SUCCESS":
      state = {
        ...state,
        loading: false,
        contract: action.payload
      };
      break;
    case "LOBA_GET_CONTRACT_ERROR":
      state = {
        ...state,
        loading: false,
        loadContractError: action.payload
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default web3Reducer;
