import React from "react";
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LobaAddContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      contractDesignation: '',
      contractWallet: ''
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.hydrateStateWithLocalStorage();
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    // saves if component has a chance to unmount
    this.saveStateToLocalStorage();
  }

  hydrateStateWithLocalStorage() {
    if (localStorage.hasOwnProperty('page-state-loba-create-contract')) {
      let stg = localStorage.getItem('page-state-loba-create-contract');
      //console.log(stg);
      try {
        stg = JSON.parse(stg);
        for (let key in this.state) {
          //console.log("key: %o | value: %o", key, stg[key]);
          this.setState({ [key]: stg[key] });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  saveStateToLocalStorage() {
    localStorage.setItem('page-state-loba-create-contract', JSON.stringify(this.state));
  }

  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    let errorHtml = "";
    if (this.props.error) {
      errorHtml = (
        <Alert color="danger">
          Something went wrong!
        </Alert>
      );
    }
    return (
      <div>
        <Button color="primary" className="float-right" onClick={this.toggle}>
          <FontAwesomeIcon icon="plus" />
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Create new contract</ModalHeader>
          <ModalBody>
            {errorHtml}
            <Form>
              <FormGroup>
                <Label for="contractDesignation" size="sm">Designation</Label>
                <Input
                  type="text"
                  name="contractDesignation"
                  bsSize="sm"
                  value={this.state.contractDesignation}
                  onChange={this.handleChange.bind(this)}
                />
                <Label for="contractWallet" size="sm">Referal Wallet</Label>
                <Input
                  type="text"
                  name="contractWallet"
                  bsSize="sm"
                  value={this.state.contractWallet}
                  onChange={this.handleChange.bind(this)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={this.props.loading} onClick={() => this.props.createContract(this.state.contractDesignation, this.state.contractWallet)}>Submit</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default LobaAddContract;