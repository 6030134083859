import React from "react";
import _ from "lodash";
import {
  Table, Button
} from "reactstrap";

import TaskItem from "./TaskItem";

class TasksList extends React.Component {
  deleteItem(id) {
    if (this.props.onDeleteItem) {
      this.props.onDeleteItem(id);
    }
  }
  render() {
    let taskList = "";
    if (this.props.tasks) {
      taskList = _.map(this.props.tasks, (value, key) => {
        return <TaskItem key={key} id={key} title={value.title} description={value.description} onDeleteItem={this.deleteItem.bind(this)}></TaskItem>;
      });
    }
    return (
      <div>
        <Table>
        <tbody>
        {taskList}
        </tbody>
      </Table>
        
      </div>
    );
  }
}

export default TasksList;