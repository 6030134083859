import lobaEthApi from "../api/web3Api";

export function createContract(wallet, dispatch) {
  dispatch({
    type: "WEB3_CREATE_CONTRACT"
  });
  lobaEthApi.initWeb3();
  lobaEthApi.updateAccountInfo();
  lobaEthApi.initContractInterface([{ "constant": true, "inputs": [], "name": "rewardAddr", "outputs": [{ "name": "", "type": "address" }], "payable": false, "stateMutability": "view", "type": "function" }, { "constant": true, "inputs": [], "name": "lobaAddr", "outputs": [{ "name": "", "type": "address" }], "payable": false, "stateMutability": "view", "type": "function" }, { "inputs": [{ "name": "_mainReward", "type": "address" }], "payable": false, "stateMutability": "nonpayable", "type": "constructor" }, { "payable": true, "stateMutability": "payable", "type": "fallback" }]);
  lobaEthApi.createCommissionContract(wallet, (result, error) => {
    if (!error) {
      dispatch({
        type: "WEB3_CREATE_CONTRACT_SUCCESS",
        payload: result
      });
    }
    else {
      dispatch({
        type: "WEB3_CREATE_CONTRACT_ERROR",
        payload: error
      });
    }
  })
}