import { tasks } from "../api/firebaseApi";

export function addTask(title, description, dispatch) {
  console.log('addTask - title: %o, description: %o', title, description);
  dispatch({
    type: "FB_TASKS_ADD"
  });
  tasks.addTask({ 'title': title, 'description': description });
  tasks.fetchAll((result) => {
    dispatch({
      type: "FB_TASKS_ADD_SUCCESS",
      payload: result
    })
  });
}

export function removeTask(id, dispatch) {
  dispatch({
    type: "FB_TASKS_REMOVE"
  });
  tasks.removeTask(id);
  tasks.fetchAll((result) => {
    dispatch({
      type: "FB_TASKS_REMOVE_SUCCESS",
      payload: result
    })
  });
}

export function getTasks(dispatch) {
  dispatch({
    type: "FB_TASKS_FETCH"
  });
  tasks.fetchAll((result) => {
    dispatch({
      type: "FB_TASKS_FETCH_SUCCESS",
      payload: result
    })
  });
}