import React from "react";
import {
  Card, CardTitle, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Badge
} from "reactstrap";

class PromotionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    let promotionState = "";
    if (!this.props.admin) {
      switch (this.props.state) {
        case "active":
          promotionState = (<Badge color="success">Active</Badge>)
          break;
        case "rejected":
          promotionState = (<Badge color="danger">Rejected</Badge>)
          break;
        case "pending":
          promotionState = (<Badge color="warning">Pending</Badge>)
          break;
        case "standby":
          promotionState = (<Badge color="secondary">Disabled</Badge>)
          break;
        default:
          promotionState = "";
          break;
      }
    }
    let promotionsActions = "";
    if (this.props.admin) {
      promotionsActions = (
        <div>
          <Button size="sm" outline onClick={this.toggle}>+</Button>{' '}&nbsp;{' '}
          <Button size="sm" outline color="danger">Reject</Button>{' '}
          <Button size="sm" outline color="success">Aprove</Button>
        </div>
      );
    }
    else {
      switch (this.props.state) {
        case "active":
          promotionsActions = (
            <div>
              <Button size="sm" outline onClick={this.toggle}>+</Button>{' '}&nbsp;{' '}
              <Button size="sm" outline color="danger">Stop</Button>
            </div>
          );
          break;
        case "rejected":
          promotionsActions = (
            <div>
              <Button size="sm" outline onClick={this.toggle}>+</Button>{' '}&nbsp;{' '}
              <Button size="sm" outline color="danger">Delete</Button>{' '}
              <Button size="sm" outline color="success">ReSubmit</Button>
            </div>
          );
          break;
        case "pending":
          promotionsActions = (
            <div>
              <Button size="sm" outline onClick={this.toggle}>+</Button>
            </div>
          );
          break;
        case "standby":
          promotionsActions = (
            <div>
              <Button size="sm" outline onClick={this.toggle}>+</Button>{' '}&nbsp;{' '}
              <Button size="sm" outline color="danger">Delete</Button>{' '}
              <Button size="sm" outline color="success">ReSubmit</Button>
            </div>
          );
          break;
        default:
          promotionsActions = "";
          break;
      }
    }
    return (
      <Card>
        <CardBody>
          <CardTitle>{this.props.title}{' '}{promotionState}</CardTitle>
          <h6>{this.props.partner}</h6>
          <img alt=".." src="" height="200px" width="100%"></img>
          <br /><br />


          {promotionsActions}

          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
            <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={{ size: 6 }}>
                  <img alt=".." src="" height="300px" width="100%"></img>
                </Col>
                <Col md={{ size: 6 }}>
                  <h6>{this.props.partner}</h6>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button outline color="danger">Reject</Button>{' '}
              <Button outline color="success">Aprove</Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    );
  }
}

export default PromotionCard;