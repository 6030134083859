import React from "react";
import { Navbar, Nav, NavItem, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container } from "reactstrap";
import { connect } from "react-redux";
import { logout } from "../Actions/authActions";
import { NavLink } from "react-router-dom";

class HeaderContainer extends React.Component {
  handleLogout() {
    this.props.onLogout();
  }
  render() {
    let sessionLink = "";
    if (this.props.loggedIn) {
      sessionLink = (
        <NavItem>
          <div className="loggedUser">{this.props.loggedUserEmail} ({this.props.loggedUserRole})</div>
          <Button color="primary" onClick={() => { this.props.logout() }}>
            Logout
          </Button>
        </NavItem>
      );
    }
    let mainMenu = "";
    if (this.props.loggedIn) {
      if (this.props.loggedUserRole === "admin") {
        mainMenu = (
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to="/">Home</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Interprev
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink to="/dashboard">dashboard</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink to="/promotions">promotions</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink to="/partners">partners</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Loba
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink to="/loba-contract">loba-contract</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink to="/loba-contract/123">loba-contract 123</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        );
      }
      else if (this.props.loggedUserRole === "interprev-admin") {
        mainMenu = (
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/partners">Partners</NavLink>
            </NavItem>
          </Nav>
        );
      }
      else if (this.props.loggedUserRole === "interprev-partner") {
        mainMenu = (
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to="/">Home</NavLink>
            </NavItem>
          </Nav>
        );
      }
      else if (this.props.loggedUserRole === "loba") {
        mainMenu = (
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to="/">Contracts</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/loba-test">Test</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/loba-contract/0x0fd53d511676591d90e63f5c87db2e92744066d1">contract</NavLink>
            </NavItem>
          </Nav>
        );
      }
    }
    return (
      <Navbar className="mainNav" expand="md">
        <Container>
          {mainMenu}
          <Nav className="ml-auto" navbar>
            {sessionLink}
          </Nav>
        </Container>
      </Navbar>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    loggedUserEmail: state.authReducer.loggedUserEmail,
    loggedUserRole: state.authReducer.loggedUserRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      logout(dispatch)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
