import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import authReducer from '../Reducers/authReducer';
import web3Reducer from '../Reducers/web3Reducer';
import firebaseReducer from '../Reducers/firebaseReducer';
import lobaReducer from '../Reducers/lobaReducer';

export default createStore(
  combineReducers({authReducer, web3Reducer, firebaseReducer, lobaReducer}),
  {},
  applyMiddleware(createLogger(), thunk)
);