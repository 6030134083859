import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Table
} from "reactstrap";

import { createContract, getContracts } from "../../Actions/lobaActions";
import LobaAddContract from "./components/addContract";
import ContractsListItem from "./components/contractsListItem";

class LobaContractsContainer extends Component {
  componentDidMount() {
    localStorage.setItem('lastVisitedPage', '/');
    this.hydrateStateWithLocalStorage();
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    this.props.getContracts();
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    // saves if component has a chance to unmount
    this.saveStateToLocalStorage();
  }

  hydrateStateWithLocalStorage() {
    if (localStorage.hasOwnProperty('page-state-loba-contract-list')) {
      let stg = localStorage.getItem('page-state-loba-contract-list');
      console.log(stg);
      try {
        stg = JSON.parse(stg);
        for (let key in this.state) {
          console.log("key: %o | value: %o", key, stg[key]);
          this.setState({ [key]: stg[key] });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  saveStateToLocalStorage() {
    localStorage.setItem('page-state-loba-contract-list', JSON.stringify(this.state));
  }

  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
  }

  render() {
    let contractsTableHtml = "Loading...";
    let contractListHtml = "";
    if (this.props.contractList) {
      contractListHtml = _.map(this.props.contractList, (value, key) => {
        return <ContractsListItem key={key} id={key} item={value} />;
      });
    }
    if (this.props.contractList) {
      contractsTableHtml = (
        <Table>
        <thead>
          <tr>
            <th>Designation</th>
            <th>Contract Address</th>
          </tr>
        </thead>
        <tbody>
          {contractListHtml}
        </tbody>
      </Table>
      );
    }
    else if (!this.props.loading) {
      contractsTableHtml = "No contracts";
    }
    
    return <div>
      <br />
      <h5>LOBA CONTRACTS</h5>
      <hr/>
      <div className="clearfix">
      <LobaAddContract loading={this.props.minning} createContract={this.props.createContract} error={this.props.createContractError} />
      </div>
      <br/>
      <div className="clearfix">
      {contractsTableHtml}
      </div>
      
      
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    saving: state.lobaReducer.saving,
    contract: state.lobaReducer.contract,
    minning: state.lobaReducer.minning,
    loading: state.lobaReducer.loading,
    createContractError: state.lobaReducer.createContractError,
    contractList: state.lobaReducer.contractList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createContract: (designation, wallet) => {
      console.log('----------- designation: %o, wallet: %o', designation, wallet);
      createContract(designation, wallet, dispatch);
    },
    getContracts: () => {
      getContracts(dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LobaContractsContainer);
