import React from "react";
import { connect } from 'react-redux';
import { Container } from "reactstrap";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import HomeContainer from "./home";
import LoginContainer from "./login";
import NotFoundContainer from "./404";
import HeaderContainer from "./header";
import DashboardContainer from "./interprev/dashboard";
import PromotionsContainer from "./interprev/promotions";
import PartnersContainer from "./interprev/partners";
import LobaCommissionContainer from "./loba/commission";
import LobaContractContainer from "./loba/contract";
import LobaContractsContainer from "./loba/contractList";
import Loader from "../Components/loader";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faCopy } from '@fortawesome/free-solid-svg-icons';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { defaultPage: '/' };
  }
  componentDidMount() {
    library.add(faPlus);
    library.add(faCopy);
    this.setState({
      defaultPage: localStorage.getItem('lastVisitedPage') || '/'
    });
  }
  render() {
    let homepage = (<HomeContainer />);
    if (this.props.role === 'interprev-admin') {
      homepage = (<DashboardContainer />);
    }
    else if (this.props.role === 'interprev-partner') {
      homepage = (<PromotionsContainer />);
    }
    else if (this.props.role === 'loba') {
      homepage = (<LobaContractsContainer />);
    }
    let loginRedirect = (<Redirect to="/login" />);
    console.log('this.props: %o', this.props);
    return (
      <BrowserRouter>
        <div>
          <HeaderContainer />
          <Container>
            <Loader />
            <Switch>
              <Route
                path="/"
                exact
                render={() =>
                  this.props.loggedIn ? homepage : loginRedirect
                }
              />
              <Route
                path="/dashboard"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <DashboardContainer />
                  ) : loginRedirect
                }
              />
              <Route
                path="/promotions"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <PromotionsContainer />
                  ) : loginRedirect
                }
              />
              <Route
                path="/partners"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <PartnersContainer />
                  ) : loginRedirect
                }
              />
              <Route
                path="/loba-contract"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <div>Hello</div>
                  ) : loginRedirect
                }
              />
              <Route
                path="/loba-contract/:id"
                exact
                render={({match}) =>
                  this.props.loggedIn ? (
                    <LobaContractContainer id={match.params.id} />
                  ) : loginRedirect
                }
              />
              <Route
                path="/loba-test"
                exact
                render={() =>
                  this.props.loggedIn ? (
                    <LobaCommissionContainer/>
                  ) : loginRedirect
                }
              />


              <Route
                path="/login"
                exact
                render={() =>
                  this.props.loggedIn ? <Redirect to={this.state.defaultPage} /> : <LoginContainer />
                }
              />
              <Route component={NotFoundContainer} />
            </Switch>
          </Container>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    role: state.authReducer.loggedUserRole
  }
};


export default connect(mapStateToProps)(App);
