import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class NotFoundContainer extends Component {
  render() {
    return <div className="center-text"><h3>404 - Not found</h3><br/><br/><NavLink className="nav-link" to="/">Home</NavLink></div>;
  }
}

export default NotFoundContainer;
