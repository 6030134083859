import React from "react";
import { NavLink } from "react-router-dom";

class ContractsListItem extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.item.designation}</td>
        <td>
          <NavLink className="nav-link" to={'/loba-contract/' + this.props.item.contract}>{this.props.item.contract}</NavLink>
        </td>
      </tr>
    );
  }
}

export default ContractsListItem;