import web3 from 'web3';

const lobaEthApi = {
  contractAbi: [{"constant":true,"inputs":[],"name":"referal","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"constant":true,"inputs":[],"name":"loba","outputs":[{"name":"","type":"address"}],"payable":false,"stateMutability":"view","type":"function"},{"inputs":[{"name":"_ref","type":"address"}],"payable":false,"stateMutability":"nonpayable","type":"constructor"},{"payable":true,"stateMutability":"payable","type":"fallback"}],
  activeAccount: undefined,
  contractInterface: undefined,
  contractObject: undefined,

  initWeb3: function () {
    // Checking if Web3 has been injected by the browser (Mist/MetaMask)
    if (typeof web3 !== "undefined") {
      // Use Mist/MetaMask's provider
      window.web3 = new window.Web3(window.web3.currentProvider);
    } else {
      console.log("No web3? You should consider trying MetaMask!");
      // fallback - use your fallback strategy (local node / hosted node + in-dapp id mgmt / fail)
      window.web3 = new window.Web3(
        new window.Web3.providers.HttpProvider("https://localhost:8545")
      );
    }
  },

  updateAccountInfo: function (callback) {
    console.log('updateAccountInfo');
    window.web3.eth.getAccounts(function (error, accounts) {
      if (!error) {
        window.web3.eth.getBalance(accounts[0], function (error, balance) {
          if (!error) {
            this.activeAccount = accounts[0];
            console.log('this.activeAccount: %o', this.activeAccount);
            var bal = parseFloat(window.web3.fromWei(balance.toNumber(), "ether")).toFixed(3);
            var obj = {
              "address": accounts[0],
              "balance": bal
            }
            if (callback) {
              callback(obj);
            }
          } else {
            console.error(error);
          }
        }.bind(this));
      } else {
        console.error(error);
      }
    }.bind(this));
  },

  initContractInterface: function () {
    console.log('initContractInterface');
    if (!this.contractInterface) {
      this.contractInterface = window.web3.eth.contract(this.contractAbi);
    }
  },

  createCommissionContract: function (_mainReward, callback) {
    this.initContractInterface();
    console.log('Creating a new contract with _mainReward:%o | this.activeAccount: %o | this.contractInterface: %o', _mainReward, this.activeAccount, this.contractInterface);
    const contractData = '0x608060405234801561001057600080fd5b506040516020806103368339810180604052602081101561003057600080fd5b8101908080519060200190929190505050806000806101000a81548173ffffffffffffffffffffffffffffffffffffffff021916908373ffffffffffffffffffffffffffffffffffffffff16021790555033600160006101000a81548173ffffffffffffffffffffffffffffffffffffffff021916908373ffffffffffffffffffffffffffffffffffffffff16021790555050610264806100d26000396000f3fe608060405260043610610046576000357c0100000000000000000000000000000000000000000000000000000000900480632eb81e1c1461013f578063b230b1f114610196575b6000809054906101000a900473ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff166108fc60046001340281151561009057fe5b049081150290604051600060405180830381858888f193505050501580156100bc573d6000803e3d6000fd5b50600160009054906101000a900473ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff166108fc3073ffffffffffffffffffffffffffffffffffffffff16319081150290604051600060405180830381858888f1935050505015801561013c573d6000803e3d6000fd5b50005b34801561014b57600080fd5b506101546101ed565b604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390f35b3480156101a257600080fd5b506101ab610212565b604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390f35b6000809054906101000a900473ffffffffffffffffffffffffffffffffffffffff1681565b600160009054906101000a900473ffffffffffffffffffffffffffffffffffffffff168156fea165627a7a723058207337d4fae82de363e5c2c9fa21893b6830085fd32aa3504ba468caddd1af79fa0029';
    this.contractInterface.new(
      _mainReward,
      {
        from: this.activeAccount,
        data: contractData,
        gas: '2000000'
      },
      function (error, contract) {
        console.log('createCommissionContract - error: %o, contract: %o', error, contract);
        if (!error) {
          if (typeof contract.address !== 'undefined') {
            this.contractObject = contract;
            callback(this.contractObject);
          }
        }
        else {
          console.log('error: %o', error);
          console.log('contract: %o', contract);
          callback(null, 'FAILED');
        }
      }.bind(this)
    );
  },

  loadContract: function (address, callback) {
    this.initContractInterface();
    this.contractObject = this.contractInterface.at(address);
    if (this.contractObject) {
      callback(this.contractObject);
    }
    else {
      callback("Invalid Contract", null);
    }
  },

}

export default lobaEthApi