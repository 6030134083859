let configJson = {
  "users": [
    {
      "email": "inyan",
      "password": "D3faultPass.",
      "role": "admin"
    },
    {
      "email": "interprev",
      "password": "pass123",
      "role": "interprev-admin"
    },
    {
      "email": "interprev-partner",
      "password": "pass123",
      "role": "interprev-partner"
    },
    {
      "email": "loba",
      "password": "loba",
      "role": "loba"
    }
  ]
}

const config = (key) => {
  return configJson[key] || false
}

export default config