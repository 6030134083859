import firebase from 'firebase';

import { FirebaseConfig } from "../data/firebase";
firebase.initializeApp(FirebaseConfig);

let tasksRef = firebase.database().ref('tasks');
export const tasks = {
  addTask: function(obj) {
    tasksRef.push().set(obj);
  },

  removeTask: function(id) {
    tasksRef.child(id).remove();
  },

  fetchAll: function(callback) {
    tasksRef.on("value", snapshot => {
      callback(snapshot.val());
    });
  }
}