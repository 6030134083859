import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form, FormGroup, Label, Input, Button
} from "reactstrap";

import { createContract } from "../../Actions/web3Actions";
import JsonToHtml from "../../Components/JsonToHtml";

class LobaCommissionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractWallet: ''
    };
  }

  componentDidMount() {
    localStorage.setItem('lastVisitedPage', '/loba-test');
    this.hydrateStateWithLocalStorage();
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    // saves if component has a chance to unmount
    this.saveStateToLocalStorage();
  }

  hydrateStateWithLocalStorage() {
    if (localStorage.hasOwnProperty('page-state-loba-commissions')) {
      let stg = localStorage.getItem('page-state-loba-commissions');
      console.log(stg);
      try {
        stg = JSON.parse(stg);
        for (let key in this.state) {
          console.log("key: %o | value: %o", key, stg[key]);
          this.setState({ [key]: stg[key] });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  saveStateToLocalStorage() {
    localStorage.setItem('page-state-loba-commissions', JSON.stringify(this.state));
  }

  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
  }

  render() {
    let contractHtml = "";
    if (this.props.minning) {
      if (this.props.contract && this.props.contract.transactionHash) {
        contractHtml = (<div><div>MINING...<br/></div><br/><a href={'https://rinkeby.etherscan.io/tx/' + this.props.contract.transactionHash}>Transaction: {this.props.contract.transactionHash}</a></div>);
      }
    }
    else if (this.props.contract) {
      contractHtml = "";//(<JsonToHtml object={this.props.contract} />)
    }
    return <div>
      <br />
      <h5>LOBA COMISSIONS</h5>
      <hr/>
      <Form>
          <FormGroup>
            <Label for="contractWallet" size="sm">Referal Wallet</Label>
            <Input
              type="text"
              name="contractWallet"
              bsSize="sm"
              value={this.state.contractWallet}
              onChange={this.handleChange.bind(this)}
            />
          </FormGroup>
      </Form>
      <Button type="primary" disabled={this.props.loading} onClick={() => this.props.createContract(this.state.contractWallet)}>Submit</Button>
      <br/>
      {contractHtml}
      <br/>
      <hr/>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.web3Reducer.loading,
    contract: state.web3Reducer.contract,
    minning: state.web3Reducer.minning,
    createContractError: state.web3Reducer.createContractError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createContract: (wallet) => {
      createContract(wallet, dispatch);
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LobaCommissionContainer);
