import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row
} from "reactstrap";


class PartnersContainer extends Component {
  componentDidMount() {
    localStorage.setItem('lastVisitedPage', '/');
  }
  render() {
    return <div>
      <br />
      <h5>PARTNERS</h5>
      <Row>
      </Row>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersContainer);
