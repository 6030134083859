import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row, Col
} from "reactstrap";

import PromotionCard from "./components/promotion-card";
import UserCard from "./components/user-card";

class DahsboardContainer extends Component {
  componentDidMount() {
    localStorage.setItem('lastVisitedPage', '/');
  }
  render() {
    return <div>
      <br />
      <Row>
        <Col md={{ size: 8 }}>
          <h5>PROMOTIONS</h5>
          <Row>
            <Col md={{ size: 6 }}>
              <PromotionCard title="My First Promotion" partner="Parceiro Xpto" admin></PromotionCard>
            </Col>
            <Col md={{ size: 6 }}>
              <PromotionCard title="Descontos Xpto 30%" partner="Parceiro Xpto" admin></PromotionCard>
            </Col>
          </Row>
        </Col>
        <Col md={{ size: 4 }}>
          <h5>USERS</h5>
          <UserCard partner="Parceiro Segundo" email="parceiro2@mailinator.com"></UserCard>
          <br /><br />
          <UserCard partner="Parceiro Terceiro" email="terceiro@mailinator.com"></UserCard>
        </Col>
      </Row>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DahsboardContainer);
