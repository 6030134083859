import React from "react";
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input
} from "reactstrap";

class AddTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      title: "",
      description: ""
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  open() {
    this.setState({
      modalOpen: true
    });
  }

  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change);
  }

  addTask(title, description) {
    this.props.onAddTask(title, description);
    this.toggle();
  };

  render() {
    return (
      <Modal isOpen={this.state.modalOpen} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>Add Task</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="text"
                name="description"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button outline color="success" onClick={this.addTask.bind(this, this.state.title, this.state.description)}>Add</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddTask;