import React from "react";
import {
  Card, CardTitle, CardBody, Button, Row, Col
} from "reactstrap";
import { NavLink } from "react-router-dom";
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ContractInfo extends React.Component {

  copyToClipboard(text) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }

  render() {
    if (this.props.contract) {
      return (
        <div>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Card className="contractCard">
                <CardBody>
                  <CardTitle><b>Comission Smart Contract</b></CardTitle>
                  <h6>Any payment transfer for the following address will trigger the contract, that will split 25% to referal, and 75% to Loba</h6>
                  <br />
                  <QRCode value={this.props.contract.address} />
                  <br /><br /><br />
                  <Button onClick={() => this.copyToClipboard(this.props.contract.address)} color="secondary">{this.props.contract.address}{' '}&nbsp;{' '}<FontAwesomeIcon icon="copy" /></Button>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="12">
              <br />
            </Col>
            <Col sm="12" md={{ size: 6 }}>
              <Card className="contractCard">
                <CardBody>
                  <CardTitle><b>Referal</b></CardTitle>
                  <h6>{this.props.contract.referal}</h6>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md={{ size: 6 }}>
              <Card className="contractCard">
                <CardBody>
                  <CardTitle><b>Loba</b></CardTitle>
                  <h6>{this.props.contract.loba}</h6>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    }
    else
      return (
        "INVALID CONTRACT"
      );
  }
}

export default ContractInfo;