import React from "react";
import {
  Card, CardTitle, CardBody, Button
} from "reactstrap";

class UserCard extends React.Component {

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle>{this.props.partner}</CardTitle>
          <h6>{this.props.email}</h6>
          <br/>
          <Button size="sm" outline>+</Button>{' '}&nbsp;{' '}
          <Button size="sm" outline color="danger">Reject</Button>{' '}
          <Button size="sm" outline color="success">Aprove</Button>
        </CardBody>
      </Card>
    );
  }
}

 export default UserCard;