import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Provider } from "react-redux";
import store from './Store';
import App from './Containers/App';

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));
