const web3Reducer = (state = {
  loading: false,
  contract: null,
  transaction: null,
  createContractError: null
}, action) => {
  switch (action.type) {
    case "WEB3_CREATE_CONTRACT":
      state = {
        ...state,
        loading: true,
        createContractError: null
      };
      break;
    case "WEB3_CREATE_CONTRACT_SUCCESS":
      state = {
        ...state,
        loading: false,
        contract: action.payload,
        createContractError: null
      };
      break;
    case "WEB3_CREATE_CONTRACT_ERROR":
      state = {
        ...state,
        loading: false,
        createContractError: action.payload
      };
      break
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default web3Reducer;
