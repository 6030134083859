import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button
} from "reactstrap";
import { addTask, removeTask, getTasks } from "../Actions/firebaseActions";
import AddTaskModal from "../Components/Tasks/AddTaskModal";
import TasksList from "../Components/Tasks/TasksList";

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.addTaskModalRef = React.createRef();
  }

  componentDidMount() {
    localStorage.setItem('lastVisitedPage', '/');
    this.props.getTasks();
  }

  handleAddTask(title, description) {
    console.log(title);
    console.log(description);
  }

  toggleModal = () => {
    this.addTaskModalRef.current.open();
  };

  render() {
    console.log(this.props.tasks);
    return <div>
      <br />
      <h5>HOME</h5>
      <hr/>
      <TasksList tasks={this.props.tasks} onDeleteItem={this.props.removeTask}></TasksList>
      <Button size="sm" outline onClick={this.toggleModal}>+</Button>{' '}&nbsp;{' '}
      <AddTaskModal ref={this.addTaskModalRef} onAddTask={this.props.addTask}></AddTaskModal>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.firebaseReducer.loading,
    tasks: state.firebaseReducer.tasks
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTask: (title, description) => {
      addTask(title, description, dispatch);
    },
    getTasks: () => {
      getTasks(dispatch);
    },
    removeTask: (id) => {
      removeTask(id, dispatch)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
