import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row, Col
} from "reactstrap";

import PromotionCard from "./components/promotion-card";

class PromotionsContainer extends Component {
  componentDidMount() {
    localStorage.setItem('lastVisitedPage', '/');
  }
  render() {
    return <div>
      <br />
      <h5>PROMOTIONS</h5>
      <Row>
        <Col md={{ size: 4 }}>
          <PromotionCard title="My First Promotion" partner="Parceiro Xpto" state="active"></PromotionCard>
        </Col>
        <Col md={{ size: 4 }}>
          <PromotionCard title="Descontos Xpto 30%" partner="Parceiro Xpto" state="pending"></PromotionCard>
        </Col>
        <Col md={{ size: 4 }}>
          <PromotionCard title="Rejected Promotion" partner="Parceiro Xpto" state="rejected"></PromotionCard>
        </Col>
        <Col md={{ size: 4 }}>
          <PromotionCard title="Old Promotion" partner="Parceiro Xpto" state="standby"></PromotionCard>
        </Col>
      </Row>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsContainer);
