import React from "react";
import {
  Button
} from "reactstrap";

class TaskItem extends React.Component {
  deleteItem(id) {
    if (this.props.onDeleteItem) {
      this.props.onDeleteItem(id);
    }
  }
  render() {
    return (
      <tr>
        <td><b>{this.props.title}</b></td>
        <td>{this.props.description}</td>
        <td><Button size="sm" outline onClick={this.deleteItem.bind(this, this.props.id)}>DEL</Button></td>
      </tr>
    );
  }
}

export default TaskItem;