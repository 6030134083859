import lobaEthApi from "../api/web3Api";
import { lobaFb } from "../api/lobaFirebase";

export function createContract(designation, wallet, dispatch) {
  dispatch({
    type: "LOBA_CREATE_CONTRACT"
  });
  lobaEthApi.initWeb3();
  lobaEthApi.updateAccountInfo();
  lobaEthApi.createCommissionContract(wallet, (result, error) => {
    if (!error) {
      dispatch({
        type: "LOBA_CREATE_CONTRACT_SUCCESS",
        payload: result.address
      });
      lobaFb.addContract({ 'designation': designation, 'contract': result.address });
      lobaFb.fetchAll((lobaFbResult) => {
        dispatch({
          type: "LOBA_CREATE_CONTRACT_SAVED",
          payload: lobaFbResult
        })
      });
    }
    else {
      dispatch({
        type: "LOBA_CREATE_CONTRACT_ERROR",
        payload: error
      });
    }
  })
}

export function getContracts(dispatch) {
  dispatch({
    type: "LOBA_CONTRACTS_FETCH"
  });
  lobaFb.fetchAll((result) => {
    dispatch({
      type: "LOBA_CONTRACTS_FETCH_SUCCESS",
      payload: result
    })
  });
}

export function getContract(contract_address, dispatch) {
  dispatch({
    type: "LOBA_GET_CONTRACT"
  });
  lobaEthApi.loadContract(contract_address, (contract, error) => {
    if (!error) {
      let output = {};
      output.address = contract.address;
      contract.referal(function (error, referal) {
        if (!error) {
          output.referal = referal;
        } else {
          console.error(error);
        }
        contract.loba(function (error, loba) {
          if (!error) {
            output.loba = loba;
          } else {
            console.error(error);
          }
          dispatch({
            type: "LOBA_GET_CONTRACT_SUCCESS",
            payload: output
          })
        });
      });
    }
    else {
      dispatch({
        type: "LOBA_GET_CONTRACT_ERROR",
        payload: error
      })
    }
  });
}