import config from "../data/config";

export function authenticate(username, password, dispatch) {
  dispatch({
    type: "AUTHENTICATE"
  });
  let users = config('users');
  let success = false;
  users.forEach(user => {
    if (user.email === username && user.password === password) {
      dispatch({
        type: "AUTHENTICATE_SUCCESS",
        payload: {
          username: username,
          role: user.role
        }
      });
      localStorage.setItem('logged', true);
      success = true;
    }
  });
  if (!success) {
    localStorage.setItem('logged', false);
    dispatch({
      type: "AUTHENTICATE_FAILURE"
    });
  }
}

export function isAuthenticated(dispatch) {
  if (localStorage.getItem('logged') === 'true') {
    dispatch({
      type: "IS_AUTHENTICATED"
    });
    return true;
  }
  return false;
}

export function logout(dispatch) {
  localStorage.setItem('logged', false);
  dispatch({
    type: "LOGOUT"
  });
}