import React, { Component } from "react";
import { connect } from "react-redux";

import { getContract } from "../../Actions/lobaActions";
import ContractInfo from "./components/contractInfo";

class LobaContractContainer extends Component {
  componentDidMount() {
    this.props.getContract(this.props.id);
  }
  render() {
    console.log('RENDER ----- %o', this.props.contract);
    return <div>
      <br />
      <h5>LOBA CONTRACT</h5>
      <hr />
      <br /><br />
      <ContractInfo contract={this.props.contract} />
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.lobaReducer.contract,
    loading: state.lobaReducer.loading,
    loadContractError: state.lobaReducer.loadContractError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContract: (id) => {
      getContract(id, dispatch);
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LobaContractContainer);
